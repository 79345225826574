// PHO color theme
:root {
  --pho_primary: #e5b611;
  --pho_primary_100: #f1e7a1;
  --pho_primary_200: #ece089;
  --pho_primary_300: #ecdd74;
  --pho_primary_400: #ebda61;
  --pho_primary_500: #d1a33c;
  --pho_primary_600: #ffca0033;

  --pho_white: #ffffff;
  --pho_white_shade1: #f4f4f4;
  --pho_white_shade2: #e9e9e9;
  --pho_white_shade3: #bdbdbd;
  --pho_white_shade4: #666666;
  --pho_white_shade1a: #efefef;
  --pho_white_shade1b: #cecece;
  --pho_white_shade1c: #fcfaec;
  --pho_white_shade1d: rgba(167, 167, 167, 0.06);
  --pho_white_shade1e: #d9d9d9;
  --pho_white_shade3a: #ededed;

  --pho_black: #000000;
  --pho_black_shade1: #5c5c5c;
  --pho_black_shade2: #424242;
  --pho_black_shade3: #323232;
  --pho_black_shade4: #232323;
  --text_heading_bold: #232323;
  --pho_black_shade5: #979797;
  --pho_black_shade6: #bdbdbd;
  --pho_black_shade7: #a7a7a70f;
  --pho_black_shade7: rgba(0, 0, 0, 0.12);
  --pho_black_shade9: rgba(166, 166, 166, 0.25);

  --pho_red: #d14447;
  --pho_blue: #0099ff;
  --pho_green: #6ebc14;
  --pho_green_light: #eef7e3;
  --pho_orange: #e59d11;
  --pho_orange_light: #fbf3e2;
  --pho_gray: #f7f7f7;

  --pho_table_sub_header: #fcfaec;
  --pho_dd_hover_bg: #fffdf3;
  --pho_border_radius_5: 5px;
}

$smWidth: 1280px;
$mdWidth: 1440px;
$xlWidth: 1536px;

.surface-bg-color {
  background-color: var(--pho_white);
}
.primary- {
  &font-color {
    color: var(--pho_primary);
  }
  &bg-color {
    background-color: var(--pho_primary);
  }
  &border-color {
    border-color: var(--pho_primary);
  }
}
.pho-success- {
  &color {
    color: var(--pho_green);
  }
  &bg-color {
    background-color: var(--pho_green);
  }
  &border-color {
    background-color: var(--pho_green);
  }
}
.pho-error- {
  &color {
    color: var(--pho_red);
  }
  &bg-color {
    background-color: var(--pho_red);
  }
  &border-color {
    border-color: var(--pho_red);
  }
}
.pho-alert- {
  &color {
    color: var(--pho_blue);
  }
  &bg-color {
    background-color: var(--pho_blue);
  }
  &border-color {
    border-color: var(--pho_blue);
  }
}
.pho-black- {
  &color {
    color: var(--pho_black);
  }
  &bg-color {
    background-color: var(--pho_black);
  }
  &border-color {
    border-color: var(--pho_black);
  }
}
.pho-white- {
  &color {
    color: var(--pho_white);
  }
  &bg-color {
    background-color: var(--pho_white);
  }
  &border-color {
    border-color: var(--pho_white);
  }
}

.p-component {
  font-family: inherit;
}

/*
generic classes for headings and sub headings
NOTE: modifications to these classes affect in multiple files
 */

.head-h1 {
  @apply text-base text-black font-bold xl:text-xl 2xl:text-2xl;
}

.head-h2 {
  font-family: $opensans--semi;
  color: var(--text_heading_bold);
  @apply font-bold text-lg mb-4 xl:text-lg xl:mb-4 2xl:text-xl 2xl:mb-6;
}

.head-h3 {
  @apply text-sm font-bold mb-3 xl:text-sm xl:mb-4 2xl:text-base 2xl:mb-5;
}

.head-h4 {
  @apply text-xs font-bold mb-2 xl:text-sm 2xl:text-sm;
}

.head-h5 {
  @apply text-sm;

  @media (min-width: $smWidth) and (max-width: $mdWidth) {
    @apply text-xs;
  }
}

.main-head {
  @apply text-lg text-black font-bold md:text-lg xl:text-xl 2xl:text-2xl;
}

::ng-deep {
  .p-dropdown {
    @apply border;

    .p-inputtext {
      color: var(--pho_black);
      font-family: $opensans;
    }

    .p-dropdown-panel {
      background: var(--pho_white);
      color: var(--pho_black_shade3);
      @apply border border-solid rounded pl-1;
      border-color: #c8c8c8;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }

    .p-dropdown-trigger {
      width: 2rem;
    }

    .p-dropdown-trigger-icon {
      width: 11px;
      font-size: 10px;
    }
  }
}

::ng-deep
  .p-menu
  .p-menuitem:not(.p-highlight):not(.p-disabled)
  > .p-menuitem-content:hover {
  background: var(--pho_dd_hover_bg) !important;
}
::ng-deep .p-menu .p-menuitem .p-menuitem-content {
  color: var(--pho_black_shade4);
  background: var(--pho-white);
}
.kpi-label {
  @apply text-base text-gray-500 font-semibold;
}
.kpi-value {
  @apply text-2xl text-black font-extrabold;
}
// --Primary_button: var(--Primary);
// --Surface-bg: rgba(255,255,255,1.00);
// --Tertiary_button: var(--Primary_100);
// --Secondary_button_border: var(--Black);
// --Text-color: var(--white_shade4);
// --Table_heading_text: var(--black_shade3);
// --Table_header: var(--white_shade1a);
// --Text_heading_bold: var(--black_shade4);
// --Textbox_border: var(--white_shade3);
// --Sidebar: var(--black_shade4);
// --error: var(--red);
// --success: var(--green);
// --Alert: var(--blue);
